<template>
    <div class="preview">
        <div class="preview-bg" />
        <div class="preview-content">
            <div
                class="preview-close"
                @click="$emit('close-popup')">
                <icon
                    name="close"
                    color="#00A4EB"
                    size="20" />
            </div>
            <div class="preview-media">
                <img
                    v-if="assetType == IMAGE_ASSET_TYPE ||
                        assetType == SHAPE_ASSET_TYPE ||
                        assetType == DESIGN_ASSET_TYPE ||
                        assetType == TEXT_TEMPLATE_ASSET_TYPE"
                    :src="assetUrl"
                    alt="">
                <video
                    v-if="assetType == VIDEO_ASSET_TYPE"
                    muted
                    autoplay
                    controls
                    loop>
                    <source
                        :src="assetUrl"
                        type="video/mp4">
                </video>
            </div>
            <!--<div class="preview-button-wrapper">
                <button class="preview-button">
                    START AN AD
                </button>
            </div>-->
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import {
    VIDEO_ASSET_TYPE,
    IMAGE_ASSET_TYPE,
    SHAPE_ASSET_TYPE,
    TEXT_TEMPLATE_ASSET_TYPE,
    DESIGN_ASSET_TYPE
} from '@/components/ad-studio/store/constants';


export default {
    components: {
        Icon
    },
    props: {
        previewData: {
            type: [Object, Array],
            required: true,
        },
    },
    data() {
        return {
            VIDEO_ASSET_TYPE,
            IMAGE_ASSET_TYPE,
            SHAPE_ASSET_TYPE,
            TEXT_TEMPLATE_ASSET_TYPE,
            DESIGN_ASSET_TYPE
        };
    },
    computed: {
        assetType() {
            return this.previewData.asset_type.name;
        },
        assetUrl() {
            return this.previewData.url;
        },
    },
};
</script>

<style lang="scss" scoped>
.preview {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.preview-bg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.75);
}
.preview-content {
    position: relative;
    padding: 45px 45px 30px 45px;
    background: white;
    text-align: center;
}
.preview-button {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: 600;
    background: #00A4EB;
    color:white;
    cursor: pointer;
    margin-top: 30px;
}
.preview-media {
    max-width: 75vw;
    max-height: 60vh;
    margin-bottom: 15px;
    box-sizing: border-box;
    img, video {
        max-width: inherit;
        max-height: inherit;
        height: inherit;
        width: inherit;
        object-fit: cover;
    }
}
.preview-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
</style>

