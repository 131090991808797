<template>
    <div class="general">
        <!-- PREVIEW POPUP -->
        <preview-popup
            v-if="previewActive"
            :preview-data="assetForPreview"
            @close-popup="closePreview" />

        <!-- FILTERS -->
        <div class="filters">
            <div class="filters-top">
                <div class="filters-title">
                    Attributes
                </div>
                <div
                    v-if="activeTags.length || activeCustomFilters.length"
                    class="filters-clear"
                    @click="clearAll">
                    Clear all
                </div>
            </div>
            <div
                v-for="customFilter in customFilters"
                :key="customFilter.id"
                class="filters-block">
                <filter-list
                    :filter="customFilter"
                    :loading="loading"
                    :is-custom="true"
                    :active-filters="activeCustomFilters"
                    @select-tag="selectTag" />
            </div>
            <div
                v-for="(tag, index) in tags"
                :key="index"
                class="filters-block">
                <div class="filters-name mb-2">
                    {{ tag.display_name }}
                </div>
                <div
                    v-for="item in tag.tags.data"
                    :key="item.id"
                    class="mb-2">
                    <styled-checkbox
                        :key="checkboxKey"
                        :value="item.id"
                        :label="fbToMeta(item.display_name)"
                        :checked="setChecked(item)"
                        :inline="true"
                        :square="true"
                        :disabled="loading"
                        @change="selectTag($event)" />
                </div>
            </div>
        </div>
        <!-- END filters -->

        <div class="main">
            <div class="main-top">
                <!-- RESTRICTING VIDEO NOTICE -->
                <div
                    v-if="noticeActive"
                    class="notice">
                    <div
                        class="notice-close"
                        @click="closeNotice">
                        <icon
                            name="indicator-error"
                            color="#909FA8"
                            size="20" />
                    </div>
                    <div class="notice-box">
                        <div class="notice-text-wrapper">
                            <div class="notice-icon">
                                <icon
                                    name="exclamation"
                                    color="white"
                                    size="15" />
                            </div>
                            <div class="notice-text">
                                Video assets cannot be converted to designs at this time.
                            </div>
                        </div>
                    </div>
                    <div class="notice-box notice-box--spaced">
                        <div class="notice-action">
                            Remove video(s) from your list and continue
                        </div>
                        <div class="notice-action">
                            Create multiple ads
                        </div>
                    </div>
                </div>
                <!-- END restricting notice -->

                <!-- sorting items -->
                <div class="search-wrapper">
                    <div class="search">
                        <input
                            v-model="searchTerm"
                            type="text"
                            placeholder="Search assets..."
                            @input="onSearch">
                    </div>
                    <div class="sorting">
                        <div class="count">
                            <span>Total Assets: {{ total }}</span>
                            <span
                                class="select-all"
                                @click="selectAll">Select All</span>
                        </div>
                        <div class="sort">
                            <span>Sort:</span>
                            <v-select
                                v-model="sort"
                                :items="sortItems"
                                class="adslib__select select-sort"
                                @change="changeSorting" />
                        </div>
                    </div>
                </div>
                <div class="filtered">
                    <div class="library-filters">
                        <div
                            v-if="activeTags.length || activeCustomFilters.length"
                            class="library-title">
                            Filtered by:
                        </div>
                        <div class="applied">
                            <div
                                v-for="activeCustomFilter in activeCustomFilters"
                                :key="activeCustomFilter.id"
                                class="single">
                                <span>{{ activeCustomFilter.display_name }}</span>
                                <div @click="removeCustomFilter(activeCustomFilter)">
                                    <icon
                                        name="close"
                                        color="#8f9ea6"
                                        size="10"
                                        @click="removeCustomFilter(activeCustomFilter)" />
                                </div>
                            </div>
                            <div
                                v-for="activeTag in activeTags"
                                :key="activeTag.id"
                                class="single">
                                <span>{{ fbToMeta(activeTag.display_name) }}</span>
                                <div @click="removeTag(activeTag)">
                                    <icon
                                        name="close"
                                        color="#8f9ea6"
                                        size="10"
                                        @click="removeTag(activeTag)" />
                                </div>
                            </div>
                            <div
                                v-if="activeTags.length || activeCustomFilters.length"
                                class="clear"
                                @click="clearAll">
                                Clear all
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="selectedAssets.length"
                        v-outside-click="hideCreation"
                        class="selected mr-4">
                        <!--<div
                            class="selected-button"
                            @click="createDesign">
                            <div class="selected-icon">
                                <icon
                                    name="creative"
                                    color="white"
                                    size="20" />
                            </div>
                            <span class="mr-3">CREATE A DESIGN</span>
                        </div>
                        <div class="selected-or">
                            or
                        </div>-->
                        <div
                            class="selected-button"
                            @click="creationActive = true">
                            <div class="selected-circle">
                                {{ selectedAssets.length }}
                            </div>
                            <span class="mr-3">CREATE ADS</span>
                            <div class="selected-collapse">
                                <icon
                                    name="collapse-all"
                                    color="white"
                                    size="15" />
                            </div>
                        </div>
                        <div
                            v-if="creationActive"
                            class="creation">
                            <div
                                v-for="(item, index) in selectedAssets"
                                :key="index"
                                class="creation-item">
                                <div class="creation-mediaholder">
                                    <div class="creation-media">
                                        <img
                                            v-if="item.asset_type.name == 'image'"
                                            :src="item.url"
                                            alt="">
                                        <video
                                            v-if="item.asset_type.name == 'video'"
                                            muted
                                            autoplay
                                            loop>
                                            <source
                                                :src="item.url"
                                                type="video/mp4">
                                        </video>
                                    </div>
                                    <div class="creation-desc">
                                        {{ item.display_name }}
                                    </div>
                                </div>
                                <div
                                    class="creation-close"
                                    @click.stop="removeAndUnselect(item.id)">
                                    <icon
                                        name="close"
                                        color="white"
                                        size="10" />
                                </div>
                            </div>
                            <div
                                class="creation-complete"
                                @click="createMultipleAds">
                                CREATE ADS
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end sorting items -->
            </div>

            <!-- asset type filtering -->
            <div
                v-show="!loading"
                class="type">
                <span
                    class="typename"
                    :class="{active: currentMediaType == 'all'}"
                    @click="setMediaType('all')">All</span>
                <span
                    class="typename"
                    :class="{active: currentMediaType == 'images'}"
                    @click="setMediaType('images')">Images</span>
                <span
                    class="typename"
                    :class="{active: currentMediaType == 'videos'}"
                    @click="setMediaType('videos')">Videos</span>
                <span
                    class="typename"
                    :class="{active: currentMediaType == 'shapes'}"
                    @click="setMediaType('shapes')">Shapes</span>
                <span
                    class="typename"
                    :class="{active: currentMediaType == 'templates'}"
                    @click="setMediaType('templates')">Text Templates</span>
            </div>
            <!-- END asset type filtering -->

            <!-- ASSETS -->
            <div
                :key="cardKey"
                class="card-wrapper">
                <div
                    v-if="notice && selectedAssets.length"
                    class="select-all-notice">
                    Selected {{ selectedAssets.length }} displayed assets of {{ total }} total. Continue scrolling to select more.
                </div>
                <asset-card
                    v-for="(asset, index) in assets"
                    :key="index"
                    :asset-data="asset"
                    :is-selected="asset.selected"
                    @create-ad="createSingleAd"
                    @show-preview="showPreview"
                    @select-asset="selectAsset"
                    @remove-asset="removeAsset" />
            </div>
            <div v-if="loading">
                <loader />
            </div>
            <div
                v-if="!loading"
                v-intersect="getAssets" />
            <h2
                v-if="!loading && assets.length == 0"
                class="mx-5 my-5">
                Sorry, but nothing matched your search criteria...
            </h2>
            <!-- END ASSETS -->
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import AssetCard from '@/components/ads-library/AssetCard';
import PreviewPopup from '@/components/ads-library/PreviewPopup';
import Loader from '@/components/globals/Loader.vue';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import FilterList from '@/components/ads-library/FilterList';
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import EventBus from '@/event-bus';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        Icon,
        AssetCard,
        PreviewPopup,
        StyledCheckbox,
        FilterList,
        Loader
    },
    data() {
        return {
            page: 1,
            searchTerm: '',
            sort: 'Newest',
            sortItems: ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'],
            notice: false,
            loading: true,
            assets: [],
            tags: [],
            total: 0,
            activeTags: [],
            customFilters: [],
            activeCustomFilters: [],
            filtersList: [],
            tagsList: [],
            checkboxKey: 0,
            cardKey: 0,
            selectedAssets: [],
            creationActive: false,
            noticeActive: false,
            previewActive: false,
            assetForPreview: null,
            currentMediaType: 'all',
            fbToMeta
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.user.user,
            currentAgency: (state) => state.agency.currentAgency,
            currentAgencyId: (state) => state.agency.currentAgency.id,
            adsInStudio: state => state.adStudio.ads.length,
        }),
    },
    watch: {
        currentAgencyId() {
            this.reset();
            this.getAssets();
        },
        activeTags() {
            this.reset();
            this.getAssets();
        },
        activeCustomFilters() {
            this.reset();
            this.getAssets();
        },
        adsInStudio() {
            if (this.adsInStudio == 0) {
                this.reset();
                this.getAssets();
            }
        }
    },
    async mounted() {
        this.$title = 'Assets Library';
        this.getTags(1);
        this.getTags(2);
        this.getTags(3);
        const rawFilters = [{
            display_name: 'Ownership',
            tags: [
                { display_name: 'My Assets' },
                { display_name: `${this.currentAgency.name} Assets` },
                { display_name: 'BuyerBridge Assets' }
            ]
        }];
        for (const rawFilter of rawFilters) {
            const customFilter = await this.createFilterStructure(rawFilter);
            customFilter.tags.data.forEach(filter => {
                this.filtersList.push(filter);
                this.activeCustomFilters.push(filter);
            });
            this.customFilters.push(customFilter);
        }
        this.loading = false;
    },
    methods: {
        async getUUID() {
            try {
                const response = await this.$http.get('/uuid/');
                return response.data.uuid4;
            } catch(error) {
                console.log(error);
            }
        },
        async createFilterStructure(filter) {
            const data = [];
            for (const tag of filter.tags) {
                const uuid = await this.getUUID();
                const tagWithUUID = {
                    id: uuid,
                    display_name: tag.display_name
                };
                data.push(tagWithUUID);
            }
            const uuid = await this.getUUID();
            const filterStructure = {
                id: uuid,
                display_name: filter.display_name,
                tags: {
                    data
                }
            };
            return filterStructure;
        },
        changeSorting() {
            this.reset();
            this.getAssets();
        },
        reset() {
            this.notice = false;
            this.page = 1;
            this.assets = [];
        },
        selectTag(e, isCustom) {
            if (isCustom) {
                const currentFilter = this.filtersList.find(f => f.id == e.target.value);
                if (e.target.checked) {
                    this.activeCustomFilters.push(currentFilter);
                } else {
                    this.removeCustomFilter(currentFilter);
                }
            } else {
                const currentTag = this.tagsList.find(tag => tag.id == e.target.value);
                if (e.target.checked) {
                    this.activeTags.push(currentTag);
                } else {
                    this.removeTag(currentTag);
                }
            }
        },
        removeTag(tag) {
            const tagIndex = this.activeTags.indexOf(tag);
            if (tagIndex > -1) {
                this.activeTags.splice(tagIndex, 1);
            }
            this.checkboxKey += 1;
        },
        removeCustomFilter(filter) {
            const filterIndex = this.activeCustomFilters.indexOf(filter);
            if (filterIndex > -1) {
                this.activeCustomFilters.splice(filterIndex, 1);
            }
        },
        setChecked(tag) {
            if (!this.activeTags.includes(tag)) {
                return false;
            } else {
                return true;
            }
        },
        clearAll() {
            this.searchTerm = '';
            this.activeTags = [];
            this.activeCustomFilters = [];
            this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'];
            this.sort = 'Newest';
        },
        onSearch: debounce(function() {
            this.reset();
            // adding relevance when we have something in search field
            if (this.searchTerm != '') {
                this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)', 'Relevance'];
                this.sort = 'Relevance';
            } else {
                this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'];
                this.sort = 'Newest';
            }
            this.getAssets();
        }, 500),
        removeSomeTags(tagGroup) {
            const filteredTags = [];
            tagGroup.tags.data.forEach(tag => {
                // add here any name that you want to filter out
                if (
                    tag.name !== 'transparent'
                ) {
                    filteredTags.push(tag);
                }
            });
            tagGroup.tags.data = filteredTags;
            return tagGroup;
        },
        async getTags(tagGroupID) {
            try {
                const response = await this.$http.get(`tag_groups/${tagGroupID}`, { with_relationships: 'tag_groups.tags' });
                const tagsData = [];
                const filteredTags = this.removeSomeTags(response.data.data);
                this.tags.push(filteredTags);
                tagsData.push(filteredTags);
                tagsData.forEach(tag => {
                    tag.tags.data.forEach(t => {
                        this.tagsList.push(t);
                    });
                });
            } catch(error) {
                console.log(error);
            }
        },
        async getAssets() {
            if (!this.page) {
                return;
            }
            this.loading = true;
            let direction = null;
            let dateField = 'created_at.date.keyword';

            // sorting by date
            if (this.sort == 'Newest') {
                direction = 'DESC';
            } else if (this.sort == 'Oldest') {
                direction = 'ASC';
            } else if (this.sort == 'Last Updated') {
                direction = 'DESC';
                dateField = 'updated_at.date.keyword';
            } else if (this.sort == 'Alphabetical (A-Z)') {
                direction = 'ASC';
                dateField = 'display_name.keyword';
            } else if (this.sort == 'Alphabetical (Z-A)') {
                direction = 'DESC';
                dateField = 'display_name.keyword';
            } else if (this.sort == 'Relevance') {
                direction = 'DESC';
                dateField = '_score';
            }

            // sorting by tags
            const tagData = {};
            this.tags.forEach(tagGroup => {
                const tagGroupName = {
                    [tagGroup.name]: []
                };
                Object.assign(tagData, tagGroupName);
            });
            this.activeTags.forEach(tag => {
                const tagGroupName = this.tags.find(t => t.id == tag.tag_group_id);
                tagData[tagGroupName.name].push(tag.id);
            });
            // removing empty tags... because back-end is not working with empty request...
            for (let key in tagData) {
                if (tagData[key].length == 0) {
                    delete tagData[key];
                }
            }

            // sorting by custom filters
            let userId = null;
            let agencyId = null;
            let ownership = null;
            this.activeCustomFilters.forEach(filter => {
                if (filter.display_name === 'My Assets') {
                    userId = this.currentUser.id;
                }
                if (filter.display_name === `${this.currentAgency.name} Assets`) {
                    agencyId = this.currentAgencyId;
                }
                if (filter.display_name === 'BuyerBridge Assets') {
                    ownership = 'system';
                }
            });

            let assetsToShow = [1,2,3,4];
            if (this.currentMediaType == 'images') {
                assetsToShow = [1];
            }
            if (this.currentMediaType == 'videos') {
                assetsToShow = [2];
            }
            if (this.currentMediaType == 'shapes') {
                assetsToShow = [3];
            }
            if (this.currentMediaType == 'templates') {
                assetsToShow = [4];
            }
            try {
                const data = {
                    agency_id: this.currentAgencyId,
                    q: this.searchTerm,
                    page_size: 12,
                    page: this.page,
                    asset_types: assetsToShow,
                    order: {
                        field: dateField,
                        direction
                    },
                    tags: tagData,
                    filter_user_id: userId,
                    filter_agency_id: agencyId,
                    ownership
                };
                const response = await this.$http.post('assets/search', data);
                const responseAssets = response.data.data;
                responseAssets.forEach(asset => {
                    let assetsSelected = this.selectedAssets.find(selectedAsset => selectedAsset.id == asset.id);
                    if (assetsSelected) {
                        asset.selected = true;
                    } else {
                        asset.selected = false;
                    }
                });
                this.assets.push(...responseAssets);
                this.total = response.data.meta.total;
                this.page = response.data.meta.next_page;
            } catch (error) {
                console.error('Error loading creatives', error);
            } finally {
                this.loading = false;
            }
        },
        setMediaType(type) {
            this.currentMediaType = type;
            this.reset();
            this.getAssets();
        },
        selectAsset(id) {
            const currentAsset = this.assets.find(asset => asset.id == id);
            if (currentAsset) {
                currentAsset.selected = true;
            }
            this.selectedAssets.push(currentAsset);
        },
        removeAndUnselect(id) {
            this.removeAsset(id);
            const currentAsset = this.assets.find(asset => asset.id == id);
            if (currentAsset) {
                currentAsset.selected = false;
            }
            this.cardKey += 1;
        },
        removeAsset(id) {
            const currentAsset = this.selectedAssets.find(asset => asset.id == id);
            const assetIndex = this.selectedAssets.indexOf(currentAsset);
            if (assetIndex > -1) {
                this.selectedAssets.splice(assetIndex, 1);
            }
        },
        hideCreation() {
            this.creationActive = false;
        },
        closeNotice() {
            this.noticeActive = false;
        },
        showPreview(asset) {
            this.previewActive = true;
            this.assetForPreview = asset;
        },
        closePreview() {
            this.previewActive = false;
        },
        createDesign() {
            const videoFile = this.selectedAssets.find(asset => asset.asset_type == 'video');
            if (videoFile) {
                this.noticeActive = true;
            } else {
                // BIX work goes here to create design if no videos selected
            }
        },
        openStudioForCreation(assets) {
            EventBus.$emit('open-ad-studio', { editMode: false }, { isOpenedFromLayeredDesigner: false }, { selectedAssets: assets });
        },
        createSingleAd(asset) {
            this.openStudioForCreation([ asset ]);
        },
        createMultipleAds() {
            this.openStudioForCreation([ ...this.selectedAssets ]);
        },
        selectAll() {
            this.notice = false;
            this.selectedAssets = [];
            this.assets.forEach(asset => {
                this.selectAsset(asset.id);
            });
            if (this.total > this.selectedAssets.length) {
                this.notice = true;
            }
            this.cardKey += 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.general {
    display: flex;
}
.main {
    background: #F7F8FC;
    width: 80%;
    min-height: 68vh;
}
.main-top{
    position: relative;
}
.search-wrapper {
    padding: 15px 30px;
    padding-right: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search {
    position: relative;
    min-width: 50%;
    padding-right: 4%;
    input {
        width: 100%;
        background: white;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        line-height: 38px;
        outline: none;
        padding-left: 55px;
    }
    &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left:18px;
        top: calc(50% - 9px);
        background-image: url('/img/search.svg');
        pointer-events: none;
    }
}
.sorting {
    display: flex;
    align-items: center;
    height: 37px;
}
.select-sort{
    width: 110px;
}
.count {
    border-right: 1px solid #D6DCDF;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.sort {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 20px;
}
.filtered {
    padding: 0 30px;
    border-bottom: 1px solid #D5DBDE;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.library-filters {
    padding-right: 45px;
}
.library-title {
    color: #00A4EB;
    margin-bottom: 5px;
}
.applied {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.single {
    display: flex;
    align-items: center;
    background: #D7F0FB;
    border-radius: 25px;
    padding: 5px 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    span {
        margin-right: 15px;
    }
    div {
        display: flex;
        align-items: center;
        margin-top: 2px;
        cursor: pointer;
    }
}
.clear {
    color: #00A4EB;
    text-decoration: underline;
    cursor: pointer;
}
.type {
    margin: 20px 30px 0 35px;
}
.typename {
    display: inline-block;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    &.active {
        font-weight: bold;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            top: 100%;
            left: 0;
            background: #00A4EB;
        }
    }
}

.selected {
    position: relative;
    display: flex;
    align-items: center;
}
.selected-or {
    display: inline-block;
    margin: 0 45px;
    font-weight: 700;
    color: #00A4EB;
    font-size: 18px;
}
.selected-button {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    border-radius: 5px;
    background: #00A4EB;
    cursor: pointer;
    vertical-align: top;
    & > span{
        color:white;
        font-weight: bold;
    }
}
.selected-collapse {
    transform: rotate(180deg) translateY(-3px);
}
.selected-circle {
    background: white;
    color: #00A4EB;
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 15px;
}
.selected-icon{
    width: 30px;
    height: 30px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.creation {
    position: absolute;
    z-index: 4;
    background: white;
    padding: 15px;
    width: 428px;
    right:0;
    top: calc(100% + 10px);
    box-shadow: 0 0 6px 4px rgba(0,0,0,0.1);
    border-radius: 5px;
    &:after{
        content: '';
        position: absolute;
        width:20px;
        height: 20px;
        background: white;
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
        top: -10px;
        right: 15px;
        transform: rotate(45deg);
        z-index: 99;
    }
    &:before{
        content: '';
        position: absolute;
        width:60px;
        height:20px;
        background: white;
        top: 0;
        right: 0;
        z-index: 100;
    }
}
.creation-item {
    background: #F7F8FC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 5px 10px;
    color: #00A4EB;
    font-size: 16px;
    margin-bottom: 15px;
}
.creation-mediaholder {
    display: flex;
    align-items: center;
}
.creation-media {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    img,video {
        max-width: 100%;
        max-height: 100%;
    }
}
.creation-desc {
    overflow: hidden;
    max-width: 250px;
    white-space: nowrap;
}
.creation-close {
    display: flex;
    border-radius: 50%;
    background: #909FA8;
    padding: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 15px;
}
.creation-complete {
    display: table;
    margin: 0 auto;
    text-align: center;
    color: white;
    font-weight: 700;
    cursor: pointer;
    font-size: 18px;
    padding:8px 45px;
    border-radius: 5px;
    background: #00A4EB;
}

// SIDE FILTERS
.filters {
    width: 20%;
    padding: 15px 20px;
}
.filters-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.filters-title {
    color: #00A4EB;
}
.filters-clear {
    color: #00A4EB;
    text-decoration: underline;
    cursor: pointer;
}
.filters-block {
    margin-bottom: 30px;
    padding-left: 20px;
}
.filters-name {
    color: #00A4EB;
}
.filters-checkbox {
    margin-top: 0;
    margin-bottom: 0;
}

//notice
.notice {
    position: absolute;
    top: -85px;
    left: 0;
    right: 0;
    height: calc(100% + 85px);
    background: #FFECB1;
    z-index: 9;
    display: flex;
}
.notice-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.notice-box {
    width: 50%;
    box-sizing: border-box;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    &--spaced{
        justify-content: space-around;
    }
}
.notice-text-wrapper {
    display: flex;
    align-items: center;
}
.notice-text {
    width: calc(100% - 40px);
}
.notice-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #FCD451;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.notice-action {
    font-size: 13px;
    color: #007BB3;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
.select-all {
    color: #00A4EB;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
}
.select-all-notice {
    background-color: $yellow-bg;
    border: 1px solid $yellow-dark;
    padding: 12px 20px;
    margin: 5px 37px 5px 27px;
    transform: translateY(15px);
}
</style>
